import PageContent from "components/page/PageContent";
import React from "react";
import Helmet from "react-helmet";

import html from "../assets/terms.html";
import GlobalLayout from "../components/page/GlobalLayout";

export default function Terms() {
  return (
    <GlobalLayout>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <PageContent>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            style={{ margin: "50px 20px" }}
          />
        </PageContent>
      </div>
    </GlobalLayout>
  );
}
